import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import type { HTMLElementProps, Page } from '~/types'
import { getColor, isBlock, useSettings } from '~/utils'
import { PortableText } from '../blocks'

export type BannerProps = HTMLElementProps<'div'> & {
	page?: Page
}

const StyledPortableText = styled(PortableText)`
	> p {
		&:not(:first-child) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	a {
		text-decoration: underline;
	}
`

const divStyles = { color: getColor({ theme: 'light' }) }

export const Banner: FunctionComponent<BannerProps> = ({ page }) => {
	const settings = useSettings().siteSettings

	if (!settings.banner || (!settings.siteWideBanner && !page?.enableBanner)) return null

	// very crude PortableText empty validation
	const isEmpty = settings.banner.every(
		(block) => isBlock(block) && block.children.every((child) => child.text.trim() === '')
	)

	if (isEmpty) return null

	return (
		<div className="bg-brand-200 px-4 py-3 text-center md:px-12" style={divStyles}>
			<StyledPortableText blocks={settings.banner} />
		</div>
	)
}
